import './App.css'
import React, { useState, useEffect } from 'react'
import { supabase } from './util/supabaseClient'
import Auth from './page/Auth'
import NewsList from './page/NewsList'
import NewsPaperList from './page/NewsPaperList'
import NewsPaperDateList from './page/NewsPaperDateList'
import BbsList from './page/BbsList'
import ArticleDetail from './page/ArticleDetail'
import Account from './page/Account'
import { Routes, Route, useNavigate } from "react-router-dom";
import { Grid, BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ChatIcon from '@mui/icons-material/Chat';

export default function App() {
  const [session, setSession] = useState(null)
  const [store, setStore] = useState(null)
  const [value, setValue] = React.useState(0);

  const navigate = useNavigate();
  const clickNav = (path) => {
    console.log(path)
    navigate(path)
  }
  useEffect(() => {
    setSession(supabase.auth.session())
    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])
  return (
    <Grid container justifyContent="center">
      <Grid item md={8} xs={11} sx={{ pb: 7 }}>
        <Routes>
          <Route path="/" element={<div className="container" >{!session ? <Auth /> : <NewsPaperList store={store} />}</div>} />
          <Route path="/account/" element={<div className="container">{!session ? <Auth /> : <Account key={session.user.id} session={session} />}</div>} />
          <Route path="/news" element={<div className="container">{!session ? <Auth /> : <NewsPaperList store={store} />}</div>} />
          <Route path="/news/:newsPaper" element={<div className="container">{!session ? <Auth /> : <NewsPaperDateList store={store} />}</div>} />
          <Route path="/news/:newsPaper/:date" element={<div className="container">{!session ? <Auth /> : <NewsList store={store} />}</div>} />
          <Route path="/news/:newsPaper/:date/:id" element={<div className="container">{!session ? <Auth /> : <ArticleDetail store={store} />}</div>} />
          <Route path="/bbs" element={<div className="container">{!session ? <Auth /> : <BbsList store={store} />}</div>} />
        </Routes>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}>
            <BottomNavigationAction label="News" icon={<NewspaperIcon />} onClick={() => clickNav("/news")} />
            <BottomNavigationAction label="Bbs" icon={<ChatIcon />} onClick={() => clickNav("/bbs")} />
          </BottomNavigation>
        </Paper>
      </Grid>
    </Grid>
  )
}
