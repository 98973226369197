import React from 'react'
import { useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { supabase } from '../util/supabaseClient'
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { mapSourceName } from '../util/news';
import { List, ListItem } from '@mui/material';
import Divider from '@mui/material/Divider';

export default function NewsPaperDateList() {
    const params = useParams()
    const [sourceList, setSourceList] = useState([])
    const [loading, setLoading] = useState(false);
    const getArticleList = async () => {
        setLoading(true)
        try {
            let { data, error, status } = await supabase
                .rpc('query_news_paper_date', {_source: params.newsPaper})
            console.log(data, error, status)
            setSourceList(data)
        }
        finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        console.log("use effect")
        getArticleList()
    }, [])

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">Home</Link>,
        <Link underline="hover" key="1" color="inherit" href="/news">News</Link>,
        <Typography key="3" color="text.primary">
            {mapSourceName(params.newsPaper)}
        </Typography>,
    ];
    return (
        <>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            {loading ? (
                <div>...Data Loading.....</div>
            ) : (
                <div>
                        <List>
                            {sourceList.map(source => (
                                <ListItem key={source.source}>
                                    <Grid container>
                                        <Grid item xs={6}> <Link href={`/news/${params.newsPaper}`}>{mapSourceName(params.newsPaper)}</Link> </Grid>
                                        <Grid item xs={6}> <Link href={`/news/${params.newsPaper}/${source.max_date}`}>{source.max_date}</Link> </Grid>
                                    </Grid>
                                </ListItem>
                            ))}
                        </List>
                        <Divider />
                </div>
            )}
        </>
    )
}