import React from 'react'
import { useState, useEffect, createRef } from 'react'
import { supabase } from '../util/supabaseClient'
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { groupBy } from '../util/listUtil';
import { mapSourceName } from '../util/news';
import { List, ListItem, Button, SpeedDial, SpeedDialAction } from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import Divider from '@mui/material/Divider';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function NewsPaperList() {
    const [open, setOpen] = React.useState(false);
    const [sourceList, setSourceList] = useState([])
    const [loading, setLoading] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const getArticleList = async () => {
        setLoading(true)
        try {
            let { data, error, status } = await supabase
                .rpc('query_news_paper_max_date')
            console.log(data, error, status)
            setSourceList(data)
        }
        finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        console.log("use effect")
        getArticleList()
    }, [])

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">Home</Link>,
        <Typography key="3" color="text.primary">
            News
        </Typography>,
    ];
    return (
        <>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            {loading ? (
                <div>...Data Loading.....</div>
            ) : (
                <div>
                        <List>
                            {sourceList.map(source => (
                                <ListItem key={source.source}>
                                    <Grid container>
                                        <Grid item xs={6}> <Link href={`/news/${source.source}`}>{mapSourceName(source.source)}</Link> </Grid>
                                        <Grid item xs={6}> <Link href={`/news/${source.source}/${source.max_date}`}>{source.max_date}</Link> </Grid>
                                    </Grid>
                                </ListItem>
                            ))}
                        </List>
                        <Divider />
                </div>
            )}
        </>
    )
}