import React  from 'react'
import { supabase } from '../util/supabaseClient'

class Auth extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            email: '',
            password: ''
        }
    }

    handleLogin = async (e) => {
        e.preventDefault();
        try {
            this.setState({loading: true});
            const { error } = await supabase.auth.signIn({ email: this.state.email, password: this.state.password });
            if (error) throw error;
            alert('wait plz');
        } catch (error) {
            alert(error.error_description || error.message);
        } finally {
            this.setState({loading: false});
        }
    }

    render(){
        return (
            <div className="row flex flex-center">
                <div className="col-6 form-widget" aria-live="polite">
                    <h1 className="header">Supabase + React</h1>
                    <p className="description">Sign in via magic link with your email below</p>
                    {this.state.loading ? ( 'Sending magic link...') : (
                        <form onSubmit={this.handleLogin}>
                            <label htmlFor="email">Email</label>
                            <input
                                id="email"
                                className="inputField"
                                type="email"
                                placeholder="Your email"
                                value={this.state.email}
                                onChange={(e) => this.setState({email: e.target.value})}
                            />
                            <input
                                id="password"
                                className="inputField"
                                type="password"
                                placeholder="Your Password"
                                value={this.state.password}
                                onChange={(e) => this.setState({password: e.target.value})}
                            />
                            <button className="button block" aria-live="polite">Login</button>
                        </form>
                    )}
                </div>
            </div>
        )
    }
}
export default Auth;