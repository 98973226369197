export const groupBy = (list, key) => {
    const obj = {};
    list.forEach(item=> {
        if (!obj[item[key]]) { //如果不存在这个属性
            obj[item[key]] = [];
        }
        obj[item[key]].push(item);
    });
    return obj;
 }
 
 