import Avatar from '../component/Avatar'
import { useState, useEffect } from 'react'
import { supabase } from '../util/supabaseClient'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}
const Account = ({ session }) => {
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState(null)
  const [password, setPassword] = useState(null)
  const [website, setWebsite] = useState(null)
  const [avatar_url, setAvatarUrl] = useState(null)
  const [articleList, setArticleList] = useState([])

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name')
      }),
    })
      .catch((error) => alert(error))
  }

  useEffect(() => {
    console.log("use effect")
    getProfile()
    getArticleList()
  }, [session])

  const getArticleList = async () => {
    let { data,error, status} = await supabase
      .from('article_list')
      .select('id,title,img_list')
      .eq("nsfw", false)

    console.log(data)
    setArticleList(data)
  }
  const getProfile = async () => {
    try {
      setLoading(true)
      const user = supabase.auth.user()

      let { data, error, status } = await supabase
        .from('profiles')
        .select(`username, website, avatar_url`)
        .eq('id', user.id)
        .single()


      if (error && status !== 406) {
        throw error
      }

      if (data) {
        setUsername(data.username)
        setWebsite(data.website)
        setAvatarUrl(data.avatar_url)
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  const changePassword = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const updates = { password: password }
      let { error } = await supabase.auth.update(updates)
      if (error) throw error
    } catch (error) {
      alert(error.message)
    } finally {
      setLoading(false)
    }

  }
  const updateProfile = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      const user = supabase.auth.user()

      const updates = {
        id: user.id,
        username,
        website,
        avatar_url,
        updated_at: new Date(),
      }

      let { error } = await supabase.from('profiles').upsert(updates, {
        returning: 'minimal', // Don't return the value after inserting
      })

      if (error) {
        throw error
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div aria-live="polite">
      {loading ? (
        'Saving ...'
      ) : (
        <form onSubmit={updateProfile} className="form-widget">
          <Avatar
            url={avatar_url}
            size={150}
            onUpload={(url) => {
              setAvatarUrl(url)
              updateProfile({ username, website, avatar_url: url })
            }}
          />
          <div>Email: {session.user.email}</div>
          <div>
            <label htmlFor="username">Name</label>
            <input
              id="username"
              type="text"
              value={username || ''}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="website">Website</label>
            <input
              id="website"
              type="url"
              value={website || ''}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>
          <div>
            <button className="button block primary" disabled={loading}>
              Update profile
            </button>
          </div>
        </form>
      )}
      <button type="button" className="button block" onClick={() => supabase.auth.signOut()}>
        Sign Out
      </button>

      <div>
        <label htmlFor="password">Password</label>
        <input
          id="password"
          type="password"
          value={password || ''}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button type="button" className="button block primary" onClick={changePassword}>
        Change Password
      </button>

      <div className="col-12">
        <ul>
          {articleList.map((article) => (
            <li key={article.id}>
              <a href={`/article/${article.id}`} style={{ "width": "100%" }}><span className="col-6">{article.title}</span></a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Account
