export function mapSourceName(source) {
    switch (source) {
        case "BJQNB":
            return "北京青年报"
        case "GMRB":
            return "光明日报"
        case "JWB":
            return "今晚报"
        case "QLWB":
            return "齐鲁晚报"
        case "RMRB":
            return "人民日报"
        case "RMRBHW":
            return "人民日报海外版"
        case "XHMRDX":
            return "新华每日电讯"
        case "YZWB":
            return "扬子晚报"
        case "ZGQNB":
            return "中国青年报"
        case "ZQRB":
            return "证券日报"
        case "ZQWB":
            return "证券晚报"
        case "ZYB":
            return "中青报"
        case "ZYBHW":
            return "中青报海外版"
        case "BJRB":
            return "北京日报"
        case "BJWB":
            return "北京晚报"
        case "BJXW":
            return "北京新闻"
        case "NYT":
            return "纽约时报"
        default:
            return source
    }
}