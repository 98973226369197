import { applyMiddleware,createStore} from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { helloSaga } from './sagas'
import reducer from './reducers'

const sagaMiddleware = createSagaMiddleware()
export const history = createBrowserHistory()

const store = createStore(
   reducer,
  applyMiddleware(sagaMiddleware)
)
sagaMiddleware.run(helloSaga)
export default store