import React from 'react';
import App from './App';
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom";
import store from "./store/store"
import { createRoot } from 'react-dom/client';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App store={store} />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </ThemeProvider>
);