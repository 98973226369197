import React from 'react'
import { useState, useEffect } from 'react'
import { supabase } from '../util/supabaseClient'
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Grid, Divider } from '@mui/material';
import { groupBy } from '../util/listUtil';

export default function BbsList() {
    const [articleList, setArticleList] = useState([])
    const [loading, setLoading] = useState(false);
    const [linkMap, setLinkMap] = useState({})
    useEffect(() => {
        console.log("use effect")
        getArticleList()
    }, [])

    const getArticleList = async () => {
        setLoading(true)
        try {
            let { data } = await supabase
                .rpc('query_latest_comment')

            let tmp = groupBy(data, 'topic')
            let tmpLinkMap = {}
            data.forEach(article => {
                tmpLinkMap[article.topic] = article._uk
            })
            setArticleList(tmp)
            setLinkMap(tmpLinkMap)
        }
        finally {
            setLoading(false)
        }
    }
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">Home</Link>,
        <Typography key="3" color="text.primary">
            BBS
        </Typography>,
    ];
    return (
        <>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            {loading ? (
                <div>...Data Loading.....</div>
            ) : (
                <>
                    {Object.keys(articleList).map((topic, index) => (
                        <div key={index}>
                            <h4>
                                <Link href={linkMap[topic]}>
                                    {topic}
                                </Link>
                            </h4>
                            {articleList[topic].map((article, index) => (
                                <Grid container spacing={1} key={index}>
                                    <Grid item xs={12} md={8}>
                                        {article.content}
                                        <Divider />
                                        </Grid>
                                    <Grid item xs={6} md={2} sx={{ color: 'text.disabled' }}>{article.max_date}</Grid>
                                    <Grid item xs={6} md={2} sx={{ color: 'text.disabled' }}>{article.created_at}</Grid>
                                </Grid>
                            ))}
                            <Divider />
                        </div>
                    ))}
                </>
            )}
        </>
    )
}