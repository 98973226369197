import React from 'react'
import { useState, useEffect } from 'react'
import { supabase } from '../util/supabaseClient'
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { mapSourceName } from '../util/news';
import { List, ListItem } from '@mui/material';

export default function NewsList() {
    const params = useParams()
    const [articleList, setArticleList] = useState([])
    const [loading, setLoading] = useState(false);
    const getArticleList = async () => {
        setLoading(true)
        try {
            let { data, error, status } = await supabase
                .rpc('query_news', { _source: params.newsPaper, _date: params.date })
            console.log(data, error, status)
            setArticleList(data)
        }
        finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        console.log("use effect")
        getArticleList()
    }, [])

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">Home</Link>,
        <Link underline="hover" key="1" color="inherit" href="/news">News</Link>,
        <Link underline="hover" key="1" color="inherit" href={`/news/${params.newsPaper}`}>{mapSourceName(params.newsPaper)}</Link>,
        <Typography key="3" color="text.primary">
            {params.date}
        </Typography>,
    ];
    return (
        <>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            {loading ? (
                <div>...Data Loading.....</div>
            ) : (
                <div>

                    <List>
                        {articleList.map(article => (
                            <ListItem key={article.id}>
                                <Grid container>
                                    <Grid item sm={2} sx={{ display: { xs: 'none', md: 'block' } }}>{mapSourceName(article.source)}</Grid>
                                    <Grid item sm={3} sx={{ display: { xs: 'none', md: 'block' } }}>{article.news_paper_title}</Grid>
                                    <Grid item xs={12} sm={7}> <Link href={`/news/${article.source}/${article.news_paper_date}/${article.id}`}>{article.topic}</Link> </Grid>
                                </Grid>
                            </ListItem>
                        ))}
                    </List>
                </div>
            )}
        </>
    )
}