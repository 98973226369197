import React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { supabase } from '../util/supabaseClient'
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Grid, Divider, Button, LinearProgress, Box, ButtonGroup,IconButton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { mapSourceName } from '../util/news';

export default function ArticleDetail() {
    const [article, setArticle] = useState({ content: "", link: "" })
    const [summary, setSummary] = useState("")
    const [loading, setLoading] = useState(false);
    const params = useParams()
    useEffect(() => {
        console.log("use effect")
        getArticle()
    }, [])

    const getArticle = async () => {
        setLoading(true)
        try {
            let { data, error, status } = await supabase
                .from('news')
                .select('content,link,topic,summary,highlight')
                .eq("id", params.id)
                .limit(1)
                .single()
            console.log(data.content)
            data.summary == null ? setSummary("") : setSummary(data.summary)
            setArticle(data)
        }
        finally {
            setLoading(false)
        }
    }
    const doHighlight = async () => {
        let { data, error, status } = await supabase
            .from('news')
            .update({ highlight: !article.highlight })
            .eq("id", params.id)
        getArticle()
        console.log("highlight")
    }

    const doSummary = async () => {
        let tmp = summary;
        try {
            setLoading(true);
            setSummary("");
            // Call the summary API with the article ID POST
            const response = await fetch(`/api/news/summary?newsId=${params.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log(response)
            const data = await response.json();
            tmp = data.summary;
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            console.log("done")
            setSummary(tmp);
        }
    };
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">Home</Link>,
        <Link underline="hover" key="1" color="inherit" href="/news">News</Link>,
        <Link underline="hover" key="1" color="inherit" href={`/news/${params.newsPaper}`}>{mapSourceName(params.newsPaper)}</Link>,
        <Link underline="hover" key="1" color="inherit" href={`/news/${params.newsPaper}/${params.date}`}>{params.date}</Link>,
        <Typography key="3" color="text.primary">
            {article.topic}
        </Typography>,
    ];

    return (
        <div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            {article == null ? (
                loading ? (
                    <div>...Data Loading.....</div>) : (
                    <div>Not Found</div>
                )
            ) :
                (<>{loading ? <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box> : ""}<Grid container justifyContent="center">
                        <Grid item xs={12} md={6}>
                            <h3>
                                {article.topic}
                            </h3>
                            {summary.split("\n").map((line, index) => {
                                return <p key={index}>{line}</p>
                            })}
                            <Divider />
                            <ButtonGroup variant="text" aria-label="Basic button group">
                                <IconButton onClick={doHighlight}>
                                    {article.highlight?<StarIcon />:<StarBorderIcon/> }
                                </IconButton>
                                <Button onClick={doSummary}>Summary</Button>
                            </ButtonGroup>
                            <Divider />
                            {article.content.split("\n").map((line, index) => {
                                return <p key={index}>{line}</p>
                            })}
                            <Link href={article.link}>Origin</Link>
                        </Grid>
                    </Grid>
                </>)}

        </div>
    )
}